import 'whatwg-fetch';
import 'react-app-polyfill/stable';
import 'fontsource-roboto';
import 'fontsource-montserrat';
import 'fontsource-raleway';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { ToastContainer, Slide, toast } from 'react-toastify';

import { apolloClient } from '../apollo';
import { NODE_ENV } from '../config';
import { reduxStore, history } from '../redux';
import Routes from '../routes';

import InitUser from './InitUser';
import { ModalProvider } from './Modal/ModalContext';
import ModalRoot from './Modal/ModalRoot';
import SiteMap from './SiteMap';

export const store = reduxStore({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#76b900',
    },
    secondary: {
      main: '#442c79',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        cursor: 'pointer',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
    MUIDataTableResize: {
      resizer: {
        border: '0.1px solid rgba(224, 224, 224, .3)',
      },
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: '#FFF',
        paddingLeft: 2,
        paddingRight: 2,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.7rem',
      },
    },
  } as any,
});

const MapApp: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <SiteMap />
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

const AdminApp: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ModalProvider>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <ModalRoot />
            <InitUser>
              <ConnectedRouter history={history}>
                <Routes />
              </ConnectedRouter>
            </InitUser>
            <ToastContainer
              autoClose={2000}
              position={toast.POSITION.BOTTOM_RIGHT}
              transition={Slide}
              hideProgressBar
              closeButton={false}
              draggable={false}
              className="toast-container"
              toastClassName="toast"
            />
          </ThemeProvider>
        </ModalProvider>
      </Provider>
    </ApolloProvider>
  );
};

let App = MapApp;

if (NODE_ENV === 'development') {
  const isAdmin = !window?.location?.hostname?.includes('map');

  if (isAdmin) {
    App = AdminApp;
  }
} else {
  const isAdmin = window?.location?.hostname?.includes('admin.map');

  if (isAdmin) {
    App = AdminApp;
  }
}

export default App;
