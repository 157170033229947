import { ACTION_TYPES } from './reducers';

export const authenticatedAction = (user: any) => ({
  user,
  type: ACTION_TYPES.AUTHENTICATED,
});

export const setUserAction = (user: any) => ({
  user,
  type: ACTION_TYPES.SET_USER,
});

export const logoutAction = () => ({
  type: ACTION_TYPES.LOGOUT,
});
