import moment from 'moment';

export const parseTime = (value: string) => {
  const isValid = moment(value, 'LT', true).isValid();
  const hasTimeSpecification = value.includes('AM') || value.includes('PM');

  if (isValid && hasTimeSpecification) {
    const parseValue = moment
      .utc(value, 'LT')
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    return parseValue;
  }

  return value;
};

