import classNames from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';

import { exportExcel } from '../../helpers/exportExcel';
import getMapSiteAvailabilities from '../../helpers/getMapSiteAvailabilities';
import { ReactComponent as AddressIcon } from '../../images/address-icon.svg';
import { ReactComponent as BackArrow } from '../../images/back-arrow.svg';
import { ReactComponent as ClockIcon } from '../../images/clock-icon.svg';
import { ReactComponent as PhoneIcon } from '../../images/phone-icon.svg';

const MapResults: FunctionComponent<any> = ({
  filteredSites,
  setSelectedSite,
  selectedSite,
}) => {
  const filteredSelectedSite = useMemo(() => {
    return filteredSites.find(({ id }: any) => selectedSite?.id === id);
  }, [filteredSites, selectedSite]);

  const formattedAvailability = useMemo(() => {
    if (filteredSelectedSite) {
      return getMapSiteAvailabilities(filteredSelectedSite);
    }
  }, [filteredSelectedSite]);

  return (
    <>
      <div className="map-results-container">
        <div
          className={classNames('map-header-text', {
            selected: filteredSelectedSite,
          })}
        >
          {filteredSelectedSite ? (
            <div onClick={() => setSelectedSite(null)}>
              <BackArrow />
              &nbsp;&nbsp;Back to Search Results
            </div>
          ) : (
            'Search Results'
          )}
        </div>
        {!filteredSelectedSite && (
          <div className="map-results-button-container">
            <button onClick={() => exportExcel(filteredSites)}>Export</button>
            <button onClick={() => window.print()}>Print</button>
          </div>
        )}
      </div>
      <ul
        className={classNames('results-list', {
          'no-results': !filteredSelectedSite && !filteredSites.length,
        })}
      >
        {filteredSelectedSite && (
          <li key={filteredSelectedSite.id} className="results-item details">
            <div className="results-title">{filteredSelectedSite.name}</div>
            <div className="results-info-wrapper first">
              <div
                className={classNames('results-info-item', {
                  'fill-line': filteredSelectedSite.mapAttributes.length > 1,
                })}
              >
                <svg viewBox="0 0 24 24" width="14" height="14">
                  <path
                    fill={filteredSelectedSite.layerColor}
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                  />
                </svg>
                &nbsp;
                <div>{filteredSelectedSite.layerName}</div>
              </div>
              {filteredSelectedSite.mapAttributes.map(({ name }: any) => {
                return (
                  <div key={name} className="results-info-item">
                    <svg width="10" height="11" viewBox="0 0 10 11" fill="none">
                      <path
                        d="M1 7L3.625 10L9.25 1"
                        stroke="#1970A6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    &nbsp;
                    {name}
                  </div>
                );
              })}
            </div>
            <div className="results-info-wrapper">
              {filteredSelectedSite.distance && (
                <>
                  <div className="results-info-distance">
                    {filteredSelectedSite.distance}
                  </div>
                  <div className="info-dot">&nbsp;&sdot;&nbsp;</div>
                </>
              )}
              <div>{filteredSelectedSite.siteOpen ? 'Open' : 'Closed'}</div>
              {filteredSelectedSite.openUntil && (
                <>
                  <div className="info-dot">&nbsp;&sdot;&nbsp;</div>
                  <div>{filteredSelectedSite.openUntil}</div>
                </>
              )}
            </div>
            {filteredSelectedSite.contactPhone && (
              <div className="results-detail-wrapper">
                <div className="results-detail-item">
                  <PhoneIcon />
                  &nbsp;&nbsp;
                  <a
                    className="phone"
                    href={`tel:${selectedSite.contactPhone}`}
                  >
                    {filteredSelectedSite.contactPhone}
                  </a>
                </div>
              </div>
            )}
            <div className="results-detail-wrapper">
              <div className="results-detail-item">
                <AddressIcon />
                &nbsp;&nbsp;
                <a
                  className="address"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={filteredSelectedSite.locationUrl}
                >
                  {filteredSelectedSite.locationAddress}
                </a>
              </div>
            </div>
            {formattedAvailability?.length > 0 && (
              <div className="results-detail-wrapper">
                <div className="results-detail-item">
                  <ClockIcon />
                  &nbsp;&nbsp;
                </div>
                <div className="results-detail-item hours-wrapper">
                  {formattedAvailability.map(
                    ({ day, hours, description, weekInfo }: any) => (
                      <div key={day} className="results-detail-hours">
                        <div className="results-detail-day">{day}</div>
                        <div>
                          <div>
                            {hours}
                            {weekInfo && (
                              <>
                                &nbsp;&sdot;&nbsp;
                                {weekInfo}
                              </>
                            )}
                          </div>
                          {description && <div>{description}</div>}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
            {formattedAvailability?.length < 7 && (
              <div className="results-detail-hour-warning">
                <span>Verify info with this site</span>
                Hours may differ or be incomplete
              </div>
            )}
            {filteredSelectedSite.description && (
              <div className="results-detail-wrapper">
                <div className="results-detail-item description">
                  {filteredSelectedSite.description}
                </div>
              </div>
            )}
          </li>
        )}
        {!filteredSelectedSite &&
          filteredSites.map((site: any) => {
            const {
              id,
              name,
              layerColor,
              layerName,
              distance,
              mapAttributes,
              siteOpen,
              openUntil,
              locationAddress,
              contactName,
              contactPhone,
            } = site;
            return (
              <li
                key={id}
                className="results-item"
                onClick={() => setSelectedSite(site)}
              >
                <div className="results-title">{name}</div>
                <div className="results-print-info-wrapper">
                  {locationAddress && <span>{locationAddress}</span>}
                  {contactName && <span>{contactName}</span>}
                  {contactPhone && <span>{contactPhone}</span>}
                </div>
                <div className="results-info-wrapper first">
                  <div
                    className={classNames('results-info-item', {
                      'fill-line': mapAttributes.length > 1,
                    })}
                  >
                    <svg viewBox="0 0 24 24" width="14" height="14">
                      <path
                        fill={layerColor}
                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                      />
                    </svg>
                    &nbsp;
                    <div>{layerName}</div>
                  </div>
                  {mapAttributes.map(({ name }: any) => {
                    return (
                      <div key={name} className="results-info-item">
                        <svg
                          width="10"
                          height="11"
                          viewBox="0 0 10 11"
                          fill="none"
                        >
                          <path
                            d="M1 7L3.625 10L9.25 1"
                            stroke="#1970A6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        &nbsp;
                        {name}
                      </div>
                    );
                  })}
                </div>
                <div className="results-info-wrapper">
                  {distance && (
                    <>
                      <div className="results-info-distance">{distance}</div>
                      <div className="info-dot">&nbsp;&sdot;&nbsp;</div>
                    </>
                  )}
                  <div>{siteOpen ? 'Open' : 'Closed'}</div>
                  {openUntil && (
                    <>
                      <div className="info-dot">&nbsp;&sdot;&nbsp;</div>
                      <div>{openUntil}</div>
                    </>
                  )}
                </div>
              </li>
            );
          })}
        {!filteredSelectedSite && !filteredSites.length && (
          <li className="results-item no-results">
            <div>No matching results</div>
          </li>
        )}
      </ul>
    </>
  );
};

export default MapResults;
