import { apolloClient, adminUserQuery } from '../apollo';
import { store } from '../components/App';
import { authenticatedAction, logoutAction } from '../redux';

import { storeAccessToken, endSession } from './session';

const setUserEnv = async (token: any) => {
  storeAccessToken(token);

  try {
    const { data } = await apolloClient.query({
      query: adminUserQuery,
      fetchPolicy: 'network-only',
    });

    store.dispatch(authenticatedAction(data.adminUser));
  } catch (error) {
    endSession();
    store.dispatch(logoutAction());
  }
};

export default setUserEnv;
