import gql from 'graphql-tag';

export default gql`
  {
    mapAttributes {
      id
      name
      assignedMapSites
      lastUpdated
    }
  }
`;
