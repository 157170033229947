import { isString, isArray, isEmpty, isBoolean, get, omit } from 'lodash';
import moment from 'moment';

export const required = (value: any) => {
  if (isString(value) && !value.trim()) return 'Required';
  if (isArray(value) && isEmpty(value)) return 'Required';
  if (!value && typeof value !== 'number' && !isBoolean(value))
    return 'Required';
  return undefined;
};

export const hexColor = (value: string) =>
  value && !/^#[0-9A-F]{6}$/i.test(value)
    ? 'Must be a valid 6 digit HEX color'
    : undefined;

export const validTime = (value: string) => {
  if (value) {
    const isValid = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();

    if (!isValid) {
      return 'Format like 9:00 AM';
    }
  }

  return undefined;
};

export const latitude = (value: string) => {
  const pattern = new RegExp(
    '^(\\+|-)?(?:90(?:(?:\\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,8})?))$',
  );

  return value && !pattern.test(value) ? 'Invalid Latitude' : undefined;
};

export const longitude = (value: string) => {
  const pattern = new RegExp(
    '^(\\+|-)?(?:180(?:(?:\\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,8})?))$',
  );

  return value && !pattern.test(value) ? 'Invalid Longitude' : undefined;
};

export const requirelat = (value: any, allValues: any) => {
  const latitude = allValues?.latitude;
  const longitude = allValues?.longitude;
  if (longitude && !latitude) {
    return 'Latitude Required';
  }
  return undefined;
};

export const requirelng = (value: any, allValues: any) => {
  const latitude = allValues?.latitude;
  const longitude = allValues?.longitude;
  if (latitude && !longitude) {
    return 'Longitude Required';
  }
  return undefined;
};

export const validNumber = (value: string) => {
  return value && isNaN(Number(value)) ? 'Invalid format' : undefined;
};

export const validZipCode = (value: string) => {
  if (value) {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);

    if (!isValidZip) {
      return 'Invalid Zip Code';
    }

    return undefined;
  }

  return undefined;
};
export const validEmails = (value: string[]) => {
  if (value && isArray(value)) {
    const validatedEmails = value.map((email) => ({
      email,
      valid: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email),
    }));

    const invalidEmails = validatedEmails.filter(({ valid }) => !valid);

    if (invalidEmails.length > 0) {
      return `Invalid Email(s): ${invalidEmails
        .map(({ email }) => email)
        .join(', ')}`;
    }
    return undefined;
  }

  return undefined;
};

export const validateAvailabilities = (values = []) => {
  const errors = {} as any;

  const availabilityArrayErrors = [] as any;
  get(values, 'mapAvailabilities', []).forEach(
    (availability: any, index: any) => {
      const availabilityErrors = {} as any;
      const allFieldsEmpty = Object.values(
        omit(availability, ['day', 'closed', '__typename']),
      ).every((x) => isEmpty(x));
      const openTimeRequired = required(availability.openTime);
      const closeTimeRequired = required(availability.closeTime);
      const weeksRequired = required(availability.weeks);

      if (!allFieldsEmpty) {
        if (openTimeRequired) {
          availabilityErrors.openTime = openTimeRequired;
          availabilityArrayErrors[index] = availabilityErrors;
        }
        if (closeTimeRequired) {
          availabilityErrors.closeTime = closeTimeRequired;
          availabilityArrayErrors[index] = availabilityErrors;
        }
        if (weeksRequired) {
          availabilityErrors.weeks = weeksRequired;
          availabilityArrayErrors[index] = availabilityErrors;
        }
      }

      if (availabilityArrayErrors.length) {
        errors.mapAvailabilities = availabilityArrayErrors;
      }
    },
  );

  if (!isEmpty(errors)) return errors;

  return undefined;
};

export const validPhoneNumber = (value: any) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value.replace(/-/g, ''))
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

export const maxLength = (max: number) => (value: any) =>
  value && value.length > max ? `Max ${max} characters` : undefined;

export const maxLenth40 = maxLength(40);

export const maxLenth100 = maxLength(100);
