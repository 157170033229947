import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

export enum ACTION_TYPES {
  AUTHENTICATED = 'AUTHENTICATED',
  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',
}

interface Iaction {
  type?: ACTION_TYPES;
  user?: any;
}

const userReducer = (state = {}, action: Iaction = {}): any => {
  switch (action.type) {
    case 'AUTHENTICATED':
    case 'SET_USER':
      return {
        ...state,
        ...action.user,
      };
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    form: reduxFormReducer,
  });
