import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import withLayout from '../components/withLayout';

interface IAuthRouteProps extends RouteProps {
  user?: any;
}

const AuthRoute: FunctionComponent<IAuthRouteProps> = ({
  component: Component,
  ...otherProps
}: any) => {
  const AuthComponent = (props: any) => {
    const { user } = props;

    if (!user.type) {
      return <Redirect to="/login" />;
    }

    return <Component {...props} />;
  };

  const AuthenticatedComponentWithData = connect(({ user }: any) => ({
    user,
  }))(withApollo(AuthComponent));

  return <Route {...otherProps} component={AuthenticatedComponentWithData} />;
};

AuthRoute.propTypes = {
  component: PropTypes.any.isRequired,
  user: PropTypes.object,
};

AuthRoute.defaultProps = {
  user: null,
};

export default withLayout(AuthRoute);
