import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';

import { required } from '../../helpers/validators';
import ResponsiveDialog from '../ResponsiveDialog';
import TextField from '../form-fields/TextField';

const EditOrganizationForm = reduxForm({ form: 'editForm' })((props: any) => {
  const {
    dispatch,
    reset,
    handleSubmit,
    submitting,
    editFunc,
    deleteFunc,
    onRequestClose,
    initialValues,
  } = props;

  const submitAndReset = (data: any) =>
    editFunc(data).then((resData: any) => {
      if (resData) {
        onRequestClose();
        dispatch(reset('editForm'));
      }
    });

  const deleteAndReset = () => {
    return deleteFunc().then((resData: any) => {
      if (resData) {
        onRequestClose();
        dispatch(reset('editForm'));
      }
    });
  };

  return (
    <ResponsiveDialog open onClose={onRequestClose}>
      <Form autoComplete="off" onSubmit={handleSubmit(submitAndReset)}>
        <DialogTitle id="form-dialog-title">Organization Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the required information, submit to save.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="name"
                component={TextField}
                label="Name"
                validate={required}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={TextField}
                label="Description"
                fullWidth
                multiline
                rows={2}
                rowsMax={6}
              />
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            {initialValues && (
              <Button
                onClick={deleteAndReset}
                color="secondary"
                variant="outlined"
                disabled={submitting}
                style={{ marginRight: 'auto' }}
              >
                Delete
              </Button>
            )}
            <Button
              onClick={onRequestClose}
              color="primary"
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={submitting}
            >
              {submitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  &nbsp; Submit
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </ResponsiveDialog>
  );
});

export default EditOrganizationForm;
