import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { toast } from 'react-toastify';

import {
  editMapSiteMutation,
  deleteMapSiteMutation,
  mapSitesQuery,
} from '../../apollo';
import handleToastError from '../../helpers/handleToastError';

import EditSiteForm from './EditSiteForm';

const EditSiteWrapper = (props: any) => {
  const [editSite] = useMutation(editMapSiteMutation, {
    onCompleted() {
      toast('Site Updated');
    },
    onError(error) {
      handleToastError(error);
    },
    refetchQueries: [{ query: mapSitesQuery }],
    awaitRefetchQueries: true,
  });

  const [deleteMapSite, { loading: deleteLoading }] = useMutation(
    deleteMapSiteMutation,
    {
      onCompleted() {
        toast('Site Deleted');
      },
      onError(error) {
        handleToastError(error);
      },
      refetchQueries: [{ query: mapSitesQuery }],
      awaitRefetchQueries: true,
    },
  );

  const editSiteFunc = (data: any) => {
    const {
      id,
      mapOrganizationId,
      mapCategoryId,
      mapAttributeIds,
      mapAvailabilities,
      mapDemographicIds,
      name,
      address1,
      address2,
      city,
      state,
      zip,
      latitude,
      longitude,
      showOnMap,
      mobileAppSync,
      contactName,
      contactPhone,
      contactEmails,
      nutritiousFoodEmail,
      liaisonName,
      websiteUrl,
      ceresId,
      link2feedId,
      description,
    } = data;

    const validAvailabilities = mapAvailabilities.filter(
      (availability: any) => {
        const { openTime, closeTime, weeks, closed } = availability;

        return closed === true || (openTime && closeTime && weeks?.length);
      },
    );

    const normalizedAvailabilities = validAvailabilities.map(
      (availability: any) => {
        const {
          day,
          openTime,
          closeTime,
          weeks,
          description,
          closed,
        } = availability;

        return {
          day,
          openTime,
          closeTime,
          weeks: weeks?.length ? weeks : [],
          description,
          closed,
        };
      },
    );

    return editSite({
      variables: {
        id,
        input: {
          mapOrganizationId,
          mapCategoryId,
          mapAttributeIds,
          mapAvailabilities: normalizedAvailabilities,
          mapDemographicIds,
          name,
          address1,
          address2,
          city,
          state,
          zip,
          latitude: latitude ? Number(latitude) : null,
          longitude: longitude ? Number(longitude) : null,
          showOnMap,
          mobileAppSync,
          contactName,
          contactPhone,
          contactEmails,
          nutritiousFoodEmail,
          liaisonName,
          websiteUrl,
          ceresId,
          link2feedId,
          description,
        },
      },
    });
  };

  const deleteSiteFunc = () =>
    deleteMapSite({ variables: { id: props?.initialValues?.id } });

  return (
    <EditSiteForm
      editSite={editSiteFunc}
      deleteSite={deleteSiteFunc}
      deleteLoading={deleteLoading}
      {...props}
    />
  );
};

export default EditSiteWrapper;
