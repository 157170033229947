import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, formValueSelector } from 'redux-form';

import { mapConfigQuery } from '../../apollo';
import Loading from '../../helpers/Loading';
import { maxLenth100, required } from '../../helpers/validators';
import Checkbox from '../form-fields/Checkbox';
import TextField from '../form-fields/TextField';

const EditBanner = reduxForm({
  form: 'editBanner',
})((props: any) => {
  const {
    handleSubmit,
    submitting,
    updateFunc,
    initialize,
    dirty,
    anyTouched,
    requireText,
  } = props;

  const { loading, error, data } = useQuery(mapConfigQuery);

  useEffect(() => {
    if (!loading && data && !dirty && !anyTouched) {
      initialize(data?.mapConfig);
    }
  }, [data, dirty, anyTouched, initialize, loading]);

  if (loading) return <Loading error={error} />;

  return (
    <Form autoComplete="off" onSubmit={handleSubmit(updateFunc)}>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
        style={{ fontWeight: 700 }}
      >
        MAP BANNER
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="bannerEnabled"
            component={Checkbox}
            label="Show Banner"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="bannerText"
            component={TextField}
            label="Banner Text"
            required={requireText}
            validate={requireText ? [required, maxLenth100] : [maxLenth100]}
            fullWidth
            autoComplete="input-unsupported"
          />
        </Grid>
      </Grid>
      <br />
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        disabled={submitting}
      >
        Save
      </Button>
    </Form>
  );
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector('editBanner');

  return {
    requireText: selector(state, 'bannerEnabled'),
  };
};

export default connect(mapStateToProps)(EditBanner);
