import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { toast } from 'react-toastify';

import { updateMapConfigQuery } from '../../apollo';
import handleToastError from '../../helpers/handleToastError';

import EditBannerForm from './EditBannerForm';

const EditBannerWrapper = (props: any) => {
  const { setTriggerReload } = props;
  const [editMutation] = useMutation(updateMapConfigQuery, {
    onCompleted() {
      toast('Save Successful');
      setTriggerReload(true);
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const updateFunc = (data: any) => {
    const { bannerEnabled, bannerText } = data;

    return editMutation({
      variables: {
        input: {
          bannerEnabled,
          bannerText,
        },
      },
    });
  };

  return <EditBannerForm updateFunc={updateFunc} {...props} />;
};

export default EditBannerWrapper;
