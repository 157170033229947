import gql from 'graphql-tag';

export default gql`
  mutation($input: MapAttributeInput!, $id: ID) {
    editMapAttribute(input: $input, id: $id) {
      id
      name
      lastUpdated
    }
  }
`;
