import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { toast } from 'react-toastify';

import {
  editMapCategoryMutation,
  mapCategoriesQuery,
  deleteMapCategoryMutation,
} from '../../apollo';
import handleToastError from '../../helpers/handleToastError';

import EditCategoryForm from './EditCategoryForm';

const EditCategoryWrapper = (props: any) => {
  const [editMutation] = useMutation(editMapCategoryMutation, {
    onCompleted() {
      toast('Category Updated');
    },
    onError(error) {
      handleToastError(error);
    },
    refetchQueries: [{ query: mapCategoriesQuery }],
  });

  const [deleteMutation] = useMutation(deleteMapCategoryMutation, {
    onCompleted() {
      toast('Category Deleted');
    },
    onError(error) {
      handleToastError(error);
    },
    refetchQueries: [{ query: mapCategoriesQuery }],
  });

  const editFunc = (data: any) => {
    const { id, name, color } = data;

    return editMutation({
      variables: {
        id,
        input: {
          name,
          color,
        },
      },
    });
  };

  const deleteFunc = () =>
    deleteMutation({ variables: { id: props?.initialValues?.id } });

  return (
    <EditCategoryForm editFunc={editFunc} deleteFunc={deleteFunc} {...props} />
  );
};

export default EditCategoryWrapper;
