import gql from 'graphql-tag';

export default gql`
  query($categories: [ID!]) {
    mapCategorySites(categories: $categories) {
      id
      name
      color
      mapSites {
        id
        name
        description
        latitude
        longitude
        address1
        address2
        city
        state
        zip
        contactName
        contactPhone
        websiteUrl
        mapAttributes {
          id
          name
        }
        mapAvailabilities {
          day
          weeks
          openTime
          closeTime
          description
          closed
        }
      }
    }
  }
`;
