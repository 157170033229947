import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Attributes from '../components/Attributes';
import Categories from '../components/Categories';
import Dashboard from '../components/Dashboard';
import Organizations from '../components/Organizations';
import Sites from '../components/Sites';
import LoginPage from '../pages/LoginPage';

import AnonymousRoute from './AnonymousRoute';
import AuthRoute from './AuthRoute';

const Routes = (): any => (
  <Switch>
    <AuthRoute exact path="/" component={Dashboard} />
    <AuthRoute exact path="/sites" component={Sites} />
    <AuthRoute exact path="/attributes" component={Attributes} />
    <AuthRoute exact path="/categories" component={Categories} />
    <AuthRoute exact path="/organizations" component={Organizations} />
    <AnonymousRoute exact path="/login" component={LoginPage} />
    <AnonymousRoute component={() => <Redirect to="/login" />} />
  </Switch>
);

export default Routes;
