import React from 'react';

import { ModalContext } from './ModalContext';

const ModalRoot = () => (
  <ModalContext.Consumer>
    {(props) => {
      const {
        component: Component,
        hideModal,
        props: otherProps,
      } = props as any;

      return (
        Component && <Component onRequestClose={hideModal} {...otherProps} />
      );
    }}
  </ModalContext.Consumer>
);

export default ModalRoot;
