import { List, ListItem, Button, colors, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dashboard as DashboardIcon,
  LocationOnOutlined,
  Check as CheckIcon,
  Assignment as AssignmentIcon,
  Business as BusinessIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment } from 'react';
import { NavLink as RouterLink, LinkProps } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    border: 'none !important',
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  listItemRoot: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  iconButtonRoot: {
    borderRadius: '50% !important',
  },
}));

const CustomRouterLink = forwardRef((props: LinkProps, ref: any) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink exact {...props} />
  </div>
));

interface LinkButtonProps extends ButtonProps {
  activeClassName?: string;
}

const LinkButton = (props: LinkButtonProps & LinkProps) => (
  <Button {...props} component={CustomRouterLink as any} />
);

const SidebarNav: React.FC<any> = (props) => {
  const { className, dispatch, ...rest } = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <Fragment>
        <ListItem className={classes.item} disableGutters>
          <LinkButton
            activeClassName={classes.active}
            className={classes.button}
            to="/"
          >
            <div className={classes.icon}>
              <DashboardIcon />
            </div>
            Dashboard
          </LinkButton>
        </ListItem>
        <ListItem className={classes.item} disableGutters>
          <LinkButton
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to="/sites"
          >
            <div className={classes.icon}>
              <LocationOnOutlined />
            </div>
            Sites
          </LinkButton>
        </ListItem>
        <ListItem className={classes.item} disableGutters>
          <LinkButton
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to="/attributes"
          >
            <div className={classes.icon}>
              <CheckIcon />
            </div>
            Attributes
          </LinkButton>
        </ListItem>
        <ListItem className={classes.item} disableGutters>
          <LinkButton
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to="/categories"
          >
            <div className={classes.icon}>
              <AssignmentIcon />
            </div>
            Categories
          </LinkButton>
        </ListItem>
        <ListItem className={classes.item} disableGutters>
          <LinkButton
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to="/organizations"
          >
            <div className={classes.icon}>
              <BusinessIcon />
            </div>
            Organizations
          </LinkButton>
        </ListItem>
      </Fragment>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
};

export default SidebarNav;
