import { Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';

const ResponsiveDialog = (props: any) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      {...props}
      fullScreen={fullScreen}
      transitionDuration={0}
      maxWidth="md"
      scroll="body"
      disableRestoreFocus
    >
      {props.children}
    </Dialog>
  );
};

export default ResponsiveDialog;
