import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { CopyBlock } from 'react-code-blocks';

import { MAP_URL } from '../../config';
import EditBanner from '../EditBanner';
import EditMap from '../EditMap';

const Dashboard = () => {
  const [snippetType, setSnippetType] = useState<any>('iframe');
  const [triggerReload, setTriggerReload] = useState<boolean>(false);
  const [mapConfig, setMapConfig] = useState<any>({
    categories: [],
    enableAttributes: true,
    enableAvailability: true,
  });

  useEffect(() => {
    if (triggerReload) {
      setTriggerReload(false);
    }
  }, [triggerReload]);

  const configParams = useMemo(() => {
    if (triggerReload) {
      return '';
    }
    return `?${new URLSearchParams(mapConfig).toString()}`;
  }, [mapConfig, triggerReload]);

  const codeString = useMemo(() => {
    const innerString = `iframe width="100%" height="700px" frameBorder="0" allowFullScreen allow="geolocation" src="${MAP_URL}${configParams}" title="HFB Map"`;

    if (snippetType === 'iframe') {
      return `<${innerString} />`;
    }

    return `[${innerString}]`;
  }, [configParams, snippetType]);

  return (
    <div style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Paper style={{ padding: 16 }}>
            <EditMap initialValues={mapConfig} submitFunc={setMapConfig} />
          </Paper>
          <br />
          <Paper style={{ padding: 16 }}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              CODE SNIPPET {`${MAP_URL}${configParams}`}
            </Typography>
            <br />
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
            >
              <InputLabel>SNIPPET TYPE</InputLabel>
              <Select
                value={snippetType}
                onChange={(e) => setSnippetType(e.target.value)}
                label="Age"
              >
                <MenuItem value="iframe">HTML iFrame</MenuItem>
                <MenuItem value="wordpress">Wordpress iFrame</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <CopyBlock
              language="markup"
              text={codeString}
              showLineNumbers={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper style={{ padding: 16 }}>
            <EditBanner setTriggerReload={setTriggerReload} />
          </Paper>
          <br />
          <Paper className="map-preview-wrapper">
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              allow="geolocation"
              src={`${MAP_URL}${configParams}`}
              title="HFB Map"
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
