import { CircularProgress } from '@material-ui/core';
import React from 'react';

interface ILoadingProps {
  error?: any;
}

const Loading: React.SFC<ILoadingProps> = ({ error }) => {
  if (error) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Error Loading...
      </div>
    );
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loading;
