import ChipInput from 'material-ui-chip-input';
import React from 'react';

const ChipTextField = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  ...custom
}: any) => {
  return (
    <ChipInput
      variant="outlined"
      placeholder={label}
      label={label}
      defaultValue={input.value || []}
      onChange={input.onChange}
      blurBehavior="add"
      error={touched && Boolean(error)}
      helperText={touched && Boolean(error) ? error : helperText}
      delayBeforeAdd
      disableUnderline
      InputProps={{ autoComplete: 'input-unsupported' }}
      {...custom}
    />
  );
};

export default ChipTextField;
