import gql from 'graphql-tag';

export default gql`
  mutation($input: MapConfigInput!) {
    updateMapConfig(input: $input) {
      id
      mapConfigId
      bannerEnabled
      bannerText
    }
  }
`;
