import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface IAnonRouteProps extends RouteProps {
  userType?: any;
}

const AnonymousRoute: FunctionComponent<IAnonRouteProps> = ({
  component,
  userType,
  ...otherProps
}: any) => {
  if (userType) {
    return <Redirect to="/" />;
  }

  return <Route component={component} {...otherProps} />;
};

AnonymousRoute.propTypes = {
  component: PropTypes.any.isRequired,
  userType: PropTypes.string,
};

AnonymousRoute.defaultProps = {
  userType: undefined,
};

const AnonymousRouteWithData = connect(({ user }: any) => ({
  userType: user.type,
}))(AnonymousRoute);

export default AnonymousRouteWithData;
