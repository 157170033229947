class Dictionary {
  public map: any;
  public values: any;
  public labels: any;
  public options: any;

  constructor(map: any) {
    this.map = map;

    this.values = Object.keys(map);
    this.labels = Object.values(map);

    this.options = this.values.map((value: any) => ({
      value,
      label: map[value],
    }));
  }

  getLabel = (value: any) => this.map[value];

  getLabels = (values = []) => values.map(this.getLabel).join(', ');
}

const days = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

export default new Dictionary(days);
