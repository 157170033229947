import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { toast } from 'react-toastify';

import {
  editMapOrganizationMutation,
  deleteMapOrganizationMutation,
  mapOrganizationsQuery,
} from '../../apollo';
import handleToastError from '../../helpers/handleToastError';

import EditOrganizationForm from './EditOrganizationForm';

const EditOrganizationWrapper = (props: any) => {
  const [editMutation] = useMutation(editMapOrganizationMutation, {
    onCompleted() {
      toast('Organization Updated');
    },
    onError(error) {
      handleToastError(error);
    },
    refetchQueries: [{ query: mapOrganizationsQuery }],
  });

  const [deleteMutation] = useMutation(deleteMapOrganizationMutation, {
    onCompleted() {
      toast('Organization Deleted');
    },
    onError(error) {
      handleToastError(error);
    },
    refetchQueries: [{ query: mapOrganizationsQuery }],
  });

  const editFunc = (data: any) => {
    const { id, name, description } = data;

    return editMutation({
      variables: {
        id,
        input: {
          name,
          description,
        },
      },
    });
  };

  const deleteFunc = () =>
    deleteMutation({ variables: { id: props?.initialValues?.id } });

  return (
    <EditOrganizationForm editFunc={editFunc} deleteFunc={deleteFunc} {...props} />
  );
};

export default EditOrganizationWrapper;
