import gql from 'graphql-tag';

export default gql`
  {
    mapDemographics {
      ethnicities {
        id
        partnerEthnicityId
        name
      }
    }
  }
`;
