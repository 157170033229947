import React, { ReactPropTypes, ReactNode } from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import { adminUserQuery } from '../../apollo';
import Loading from '../../helpers/Loading';
import { endSession } from '../../helpers/session';
import { authenticatedAction, logoutAction } from '../../redux';

interface IProps extends ReactPropTypes {
  children: ReactNode;
  client: any;
  dispatch: any;
}

class InitUser extends React.Component<IProps, { loading: boolean }> {
  state = {
    loading: true,
  };

  async componentDidMount() {
    // hack to prevents MS login from breaking user flow
    if (window.location.hash.includes('id_token')) {
      return;
    }

    const { client, dispatch } = this.props;

    try {
      const { data } = await client.query({
        query: adminUserQuery,
        fetchPolicy: 'network-only',
      });

      dispatch(authenticatedAction(data.adminUser));
    } catch (error) {
      endSession();
      dispatch(logoutAction());
    }

    // Prevent race condition from resetting redux store
    await new Promise((resolve) => setTimeout(resolve, 100));

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { children } = this.props;

    if (loading) {
      return <Loading />;
    }

    return children;
  }
}

export default connect()(withApollo(InitUser as any));
