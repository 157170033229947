import { apolloClient } from '../apollo';

export const storeAccessToken = (accessToken: any) => {
  localStorage?.setItem('accessToken', accessToken);
};

export const endSession = () => {
  // hack to prevents MS login from breaking user flow
  if (window.location.hash.includes('id_token')) {
    return;
  }
  apolloClient.resetStore();
  localStorage?.clear();
};
