import { FormControlLabel, FormHelperText } from '@material-ui/core';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';

const Checkbox = ({
  input,
  label,
  disabled,
  meta: { touched, error },
}: any) => (
  <div style={{ float: 'left', maxWidth: '100%', userSelect: 'none' }}>
    <FormControlLabel
      style={{ textAlign: 'left' }}
      control={
        <MaterialCheckbox
          disabled={disabled}
          checked={Boolean(input.value)}
          onChange={input.onChange}
        />
      }
      label={
        <div>
          {label}
          {touched && error ? (
            <FormHelperText error>{error}</FormHelperText>
          ) : null}
        </div>
      }
    />
  </div>
);

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
};

export default Checkbox;
