import { useQuery } from '@apollo/react-hooks';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Button,
} from '@material-ui/core';
import { LocationOnOutlined, AddCircleOutline } from '@material-ui/icons';
import React, { useContext } from 'react';

import { mapSitesQuery } from '../../apollo';
import Loading from '../../helpers/Loading';
import EditSite from '../EditSite';
import { ModalContext } from '../Modal/ModalContext';

import SitesTable from './SitesTable';

const Sites = () => {
  const modal = useContext(ModalContext);

  const { loading, error, data } = useQuery(mapSitesQuery);

  if (loading || error) return <Loading error={error} />;

  return (
    <div style={{ padding: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card style={{ height: '100%', minHeight: 100 }}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    style={{ fontWeight: 700 }}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    SITES
                  </Typography>
                  <Typography variant="h3">{data.mapSites.length}</Typography>
                </Grid>
                <Grid item>
                  <Avatar
                    style={{
                      backgroundColor: '#76b900',
                      height: 56,
                      width: 56,
                    }}
                  >
                    <LocationOnOutlined style={{ height: 32, width: 32 }} />
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                startIcon={<AddCircleOutline />}
                onClick={() => modal.showModal(EditSite)}
              >
                Add Site
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <SitesTable data={data.mapSites} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Sites;
