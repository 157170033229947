import { toast } from 'react-toastify';

import getErrorMessage from './getErrorMessage';

const handleToastError = (error: any) => {
  const errorMessage = getErrorMessage(error);
  if (errorMessage) {
    toast(errorMessage, {
      toastId: errorMessage,
      autoClose: 4000,
      type: 'warning',
    });
  }
};

export default handleToastError;
