import gql from 'graphql-tag';

export default gql`
  mutation($input: MapSiteInput!, $id: ID) {
    editMapSite(input: $input, id: $id) {
      id
      mapOrganizationId
      mapOrganization {
        id
        name
      }
      mapCategoryId
      mapCategory {
        id
        name
      }
      mapAttributeIds
      mapAttributes {
        id
      }
      name
      description
      latitude
      longitude
      address1
      address2
      city
      state
      zip
      geocodedAddress
      showOnMap
      mobileAppSync
      contactName
      contactPhone
      contactEmails
      liaisonName
      websiteUrl
      ceresId
      link2feedId
      lastUpdated
      lastEditedBy
    }
  }
`;
