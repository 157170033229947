import sortBy from 'lodash/sortBy';
import moment from 'moment';

import days from '../constants/days';

const getNumberWithOrdinal = (num: number) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = num % 100;
  return num + (s[(v - 20) % 10] || s[v] || s[0]);
};

const getMapSiteAvailabilities = (mapSite: any) => {
  const today = new Date();
  const todayDate = today.getDate();
  const todayDay = today.getDay();
  const weekOfMonth = Math.ceil((todayDate + 6 - todayDay) / 7);

  const getDayOfMonth = (day:number) => {
    const actualDate = new Date();
    const actualDay = actualDate.getDate();
    const actualMonth = actualDate.getMonth();
    const actualYear = actualDate.getFullYear();

    const firstdayMonth = new Date(actualYear, actualMonth, 1);
    const weekfirstDay = firstdayMonth.getDay();
    const until = ((day) - weekfirstDay + 7) % 7;
    const numeroMiercoles = Math.floor(((actualDay - 1) - until + (7 - weekfirstDay) + 7) / 7);
    return numeroMiercoles;
  }

  const availabilities = mapSite?.mapAvailabilities.map(
    ({ day, weeks, openTime, closeTime, description, closed }: any) => {
      if (!closed && openTime && closeTime && weeks?.length) {
        const openFormat = moment.utc(openTime).minute() !== 0 ? 'h:mma' : 'ha';
        const closeFormat =
          moment.utc(closeTime).minute() !== 0 ? 'h:mma' : 'ha';

        const hours = `${moment
          .utc(openTime)
          .format(openFormat)} - ${moment.utc(closeTime).format(closeFormat)}`;
        let weekInfo;
        if (!weeks?.includes(getDayOfMonth(todayDay))) {
          const weeksWithOrdinal = sortBy(weeks).map((week: number) =>
            getNumberWithOrdinal(week),
          );
          weekInfo = `${weeksWithOrdinal.join(', ')} week only`;
        }

        return {
          dayIndex: day,
          day: days.getLabel(day),
          hours,
          description,
          weekInfo,
        };
      }
      return {
        dayIndex: day,
        day: days.getLabel(day),
        hours: 'Closed',
        description,
      };
    },
  );

  availabilities?.sort((a: any, b: any) => {
    const firstIndex = a.dayIndex;
    const secondIndex = b.dayIndex;

    return firstIndex - secondIndex;
  });

  return availabilities;
};

export default getMapSiteAvailabilities;
