import { fromPromise, toPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { toast } from 'react-toastify';

import { store } from '../../components/App';
import { endSession } from '../../helpers/session';
import setUserEnv from '../../helpers/setUserEnv';
import { userAgentApplication } from '../../pages/LoginPage';
import { logoutAction } from '../../redux';

const onErrorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        const { message } = error;

        if (
          message &&
          message === 'You must be logged in to do this' &&
          localStorage?.getItem('accessToken')
        ) {
          return fromPromise(
            (async () => {
              try {
                // refresh the token
                const accessToken = await userAgentApplication.acquireTokenSilent(
                  ['user.read', 'profile'],
                );

                setUserEnv(accessToken);
              } catch (err) {
                console.warn(`Logging out due to: ${err.message}`);

                toast('Unable to authorize, please login', {
                  toastId: 'Unable to authorize',
                  autoClose: 4000,
                  type: 'warning',
                });

                // in case it fails refreshing the token, logout
                endSession();
                store.dispatch(logoutAction());

                throw new Error('Unable to re-authorize');
              }

              return toPromise(forward(operation));
            })(),
          );
        }
        console.log(`[GraphQL error]: Message: ${message}`);
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);

      toast('Network error, please try again', {
        toastId: 'network-error',
        autoClose: 4000,
        type: 'warning',
      });
    }
  },
);

export default onErrorLink;
