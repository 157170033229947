import { useQuery } from '@apollo/react-hooks';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Business, AddCircleOutline } from '@material-ui/icons';
import React, { useContext } from 'react';

import { mapOrganizationsQuery } from '../../apollo';
import Loading from '../../helpers/Loading';
import EditOrganization from '../EditOrganization';
import { ModalContext } from '../Modal/ModalContext';

import OrganizationsTable from './OrganizationsTable';

const Organizations = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      height: '100%',
      minHeight: 100,
    },
    cardTitle: {
      fontWeight: 700,
    },
    cardAvatar: {
      backgroundColor: theme.palette.primary.main,
      height: 56,
      width: 56,
    },
    cardIcon: {
      height: 32,
      width: 32,
    },
  }));

  const classes = useStyles();
  const modal = useContext(ModalContext);

  const { loading, error, data } = useQuery(mapOrganizationsQuery);

  if (loading || error) return <Loading error={error} />;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card className={classes.cardRoot}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    className={classes.cardTitle}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    ORGANIZATIONS
                  </Typography>
                  <Typography variant="h3">
                    {data.mapOrganizations.length}
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.cardAvatar}>
                    <Business className={classes.cardIcon} />
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                startIcon={<AddCircleOutline />}
                onClick={() => modal.showModal(EditOrganization)}
              >
                Add Organization
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <OrganizationsTable data={data.mapOrganizations} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Organizations;
