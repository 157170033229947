import ColorPicker from 'material-ui-color-picker';
import PropTypes from 'prop-types';
import React from 'react';

const ColorPickerField = ({
  input: { value, onChange, ...restInput },
  meta: { touched, error },
  ...restProps
}: any) => {
  return (
    <ColorPicker
      TextFieldProps={{ value, variant: 'outlined', size: 'small' }}
      error={touched && Boolean(error)}
      helperText={touched && Boolean(error) ? error : null}
      value={value}
      onChange={onChange}
      {...restInput}
      {...restProps}
    />
  );
};

ColorPickerField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

ColorPickerField.defaultProps = {
  disabled: false,
};

export default ColorPickerField;
