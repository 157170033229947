import gql from 'graphql-tag';

export default gql`
  {
    mapCategories {
      id
      mapCategoryId
      name
      description
      color
      assignedMapSites
      lastUpdated
    }
  }
`;
