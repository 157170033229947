import gql from 'graphql-tag';

export default gql`
  {
    adminUser {
      adminId
      firstName
      lastName
      fullName
      email
      phone
      type
    }
  }
`;
