import { push } from 'connected-react-router';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { Fragment, useContext } from 'react';
import { connect } from 'react-redux';

import EditAttribute from '../EditAttribute';
import { ModalContext } from '../Modal/ModalContext';

const AttributesTable = ({ data, push }: any) => {
  const modal = useContext(ModalContext);

  const formattedResults = data.map(
    ({ id, lastUpdated, name, assignedMapSites }: any) => {
      return {
        id,
        lastUpdated,
        name,
        assignedMapSites,
      };
    },
  );

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'assignedMapSites',
      label: 'Assigned Sites',
      options: {
        searchable: false,
      },
    },
    {
      name: 'lastUpdated',
      label: 'Last Updated',
      options: {
        searchable: false,
        customBodyRender: (value: any) =>
          moment(value).format('M/D/YY, h:mm A'),
      },
    },
  ];

  const options = {
    filter: false,
    print: false,
    download: false,
    elevation: 1,
    pagination: false,
    viewColumns: false,
    selectableRows: 'none',
    sortOrder: { name: 'lastUpdated', direction: 'desc' },
    onRowClick: (rowData: any) => {
      modal.showModal(EditAttribute, {
        initialValues: {
          ...data.find(({ id }: any) => id === rowData[0]),
        },
      });
    },
  } as any;

  return (
    <Fragment>
      <MUIDataTable
        title="Attributes"
        data={formattedResults}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};

export default connect(null, { push })(AttributesTable);
