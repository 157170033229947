import moment from 'moment';

export const formatTime = (value: string) => {
  const isValid = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();

  if (isValid) {
    return moment.utc(value).format('LT');
  }

  return value || '';
};
