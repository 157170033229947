/* eslint-disable no-console */
import { Button, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserAgentApplication } from 'msal';
import React from 'react';
import { toast } from 'react-toastify';

import { AZURE_AUTHORITY_URL, AZURE_CLIENT_ID } from '../config';
import setUserEnv from '../helpers/setUserEnv';
import AppleBackground from '../images/apples.jpg';

export const userAgentApplication = new UserAgentApplication(
  AZURE_CLIENT_ID!,
  AZURE_AUTHORITY_URL!,
  () => console.log('login success'),
  { cacheLocation: 'localStorage' },
);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `linear-gradient(180deg, rgba(118, 185, 0, 0.4), rgba(118, 185, 0, 0.4)), url(${AppleBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heading: {
    marginBottom: 30,
  },
}));

const LoginPage = (): any => {
  const classes = useStyles();

  const loginAdmin = (event: any) => {
    event.preventDefault();

    const graphScopes = ['user.read', 'profile'];

    userAgentApplication.loginPopup(graphScopes).then(
      () => {
        userAgentApplication.acquireTokenSilent(graphScopes).then(
          (accessToken) => {
            setUserEnv(accessToken);
            return accessToken;
          },
          (error) => {
            console.log('acquireTokenSilent error: ', error);
            userAgentApplication.acquireTokenPopup(graphScopes).then(
              (accessToken) => {
                setUserEnv(accessToken);
                return accessToken;
              },
              (e) => {
                console.log('acquireTokenPopup error: ', e);
              },
            );
          },
        );
      },
      (error) => {
        if (error && error.length && error.includes('AADSTS50105')) {
          toast(
            "You don't have access to this application, contact your administrator",
            {
              toastId: 'No application access',
              autoClose: 4000,
              type: 'warning',
            },
          );
        }
        console.log('Login Admin Error: ', error);
      },
    );
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} className={classes.image} />
      <Grid item xs={12} sm={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            style={{ fontWeight: 700, fontSize: 25 }}
            variant="h1"
            className={classes.heading}
          >
            Map Admin Portal
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, fontSize: 32 }}
            color="secondary"
          >
            Welcome back!
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, fontSize: 32 }}
            color="secondary"
          >
            Log in to your account.
          </Typography>
          <form onSubmit={loginAdmin} className={classes.form}>
            <Button
              size="large"
              type="submit"
              variant="outlined"
              color="secondary"
              className={classes.submit}
            >
              Admin Login
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
