import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { toast } from 'react-toastify';

import {
  editMapAttributeMutation,
  mapAttributesQuery,
  deleteMapAttributeMutation,
} from '../../apollo';
import handleToastError from '../../helpers/handleToastError';

import EditSiteForm from './EditAttributeForm';

const EditAttributeWrapper = (props: any) => {
  const [editMutation] = useMutation(editMapAttributeMutation, {
    onCompleted() {
      toast('Attribute Updated');
    },
    onError(error) {
      handleToastError(error);
    },
    refetchQueries: [{ query: mapAttributesQuery }],
  });

  const [deleteMutation] = useMutation(deleteMapAttributeMutation, {
    onCompleted() {
      toast('Attribute Deleted');
    },
    onError(error) {
      handleToastError(error);
    },
    refetchQueries: [{ query: mapAttributesQuery }],
  });

  const editFunc = (data: any) => {
    const { id, name } = data;

    return editMutation({
      variables: {
        id,
        input: {
          name,
        },
      },
    });
  };

  const deleteFunc = () =>
    deleteMutation({ variables: { id: props?.initialValues?.id } });

  return (
    <EditSiteForm editFunc={editFunc} deleteFunc={deleteFunc} {...props} />
  );
};

export default EditAttributeWrapper;
