import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import formatMapSiteAvailability from './formatMapSiteAvailability';

const createColumns = () => {
  const columns = [
    { header: 'Site Type', key: 'layerName' },
    { header: 'Name', key: 'name' },
    { header: 'Address', key: 'locationAddress' },
    { header: 'Description', key: 'description' },
    { header: 'Hours of Operation', key: 'hoursOfOperation' },
    { header: 'Contact Name', key: 'contactName' },
    { header: 'Contact Phone', key: 'contactPhone' },
    { header: 'Website', key: 'websiteUrl' },
  ];

  return columns;
};

export const exportExcel = (filteredSites: any) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('HFB Sites Export');

  const filteredSitesithHours = filteredSites.map((mapSite: any) => {
    const availability = formatMapSiteAvailability(mapSite);

    return {
      hoursOfOperation: availability,
      ...mapSite,
    };
  });

  const columns = createColumns();
  const rows = filteredSitesithHours;

  sheet.columns = columns;
  sheet.addRows(rows);

  workbook.xlsx.writeBuffer().then((data) => {
    try {
      const file = new Blob([data], { type: 'base64' });

      saveAs(file, 'hfb-sites.xlsx');
    } catch (err) {
      const textFileAsBlob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      window.navigator.msSaveBlob(textFileAsBlob, 'hfb-sites.xlsx');
    }
  });
};
