import gql from 'graphql-tag';

export default gql`
  {
    mapSites {
      id
      mapOrganizationId
      mapOrganization {
        id
        name
      }
      mapCategoryId
      mapCategory {
        id
        name
      }
      mapAttributeIds
      mapAttributes {
        id
        name
      }
      mapDemographicIds
      partnerEthnicities {
        id
        name
      }
      mapAvailabilities {
        day
        weeks
        openTime
        closeTime
        description
        closed
      }
      name
      description
      latitude
      longitude
      address1
      address2
      city
      state
      zip
      geocodedAddress
      showOnMap
      mobileAppSync
      contactName
      contactPhone
      contactEmails
      nutritiousFoodEmail
      liaisonName
      websiteUrl
      ceresId
      link2feedId
      lastUpdated
      lastEditedBy
    }
  }
`;
