import { Grid, Tooltip, Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import days from '../../constants/days';
import { formatTime } from '../../helpers/formatters';
import { parseTime } from '../../helpers/parsers';
import { validTime, maxLenth40 } from '../../helpers/validators';

import Checkbox from './Checkbox';
import DropDownMenu from './DropDownMenu';
import TextField from './TextField';

const Availabilities = ({ fields, change }: any) => {
  return (
    <>
      {fields.map((availability: any, index: any) => {
        const closed = fields.get(index).closed;

        return (
          <Fragment key={fields.get(index).day}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={2}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Tooltip
                  arrow
                  title={`Copy to ${days.getLabel(fields.get(index).day + 1)}`}
                  disableFocusListener={index === 6}
                  disableHoverListener={index === 6}
                  disableTouchListener={index === 6}
                >
                  <Button
                    style={index === 6 ? { pointerEvents: 'none' } : {}}
                    disableFocusRipple
                    onClick={() => {
                      if (index !== 6) {
                        change(`mapAvailabilities[${index + 1}]`, {
                          ...fields.get(index),
                          day: index + 1,
                        });
                      }
                    }}
                  >
                    {days.getLabel(fields.get(index).day)}
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={2} style={closed ? { opacity: 0.5 } : {}}>
                <Field
                  label="Open Time"
                  component={TextField}
                  name={`${availability}.openTime`}
                  validate={validTime}
                  format={formatTime}
                  parse={parseTime}
                  normalize={(v: any) => (v || '').toUpperCase()}
                  placeholder="9:00 AM"
                  fullWidth
                  disabled={closed}
                />
              </Grid>
              <Grid item xs={12} sm={2} style={closed ? { opacity: 0.5 } : {}}>
                <Field
                  label="Close Time"
                  component={TextField}
                  name={`${availability}.closeTime`}
                  validate={validTime}
                  format={formatTime}
                  parse={parseTime}
                  normalize={(v: any) => (v || '').toUpperCase()}
                  placeholder="5:00 PM"
                  fullWidth
                  disabled={closed}
                />
              </Grid>
              <Grid item xs={12} sm={2} style={closed ? { opacity: 0.5 } : {}}>
                <Field
                  name={`${availability}.weeks`}
                  component={DropDownMenu}
                  normalize={(value: any) => {
                    if (value.includes(true)) {
                      return [1, 2, 3, 4, 5];
                    }
                    return value;
                  }}
                  options={[
                    {
                      value: true,
                      label: 'Every Week',
                    },
                    {
                      value: 1,
                      label: '1st',
                    },
                    {
                      value: 2,
                      label: '2nd',
                    },
                    {
                      value: 3,
                      label: '3rd',
                    },
                    {
                      value: 4,
                      label: '4th',
                    },
                    {
                      value: 5,
                      label: '5th',
                    },
                  ].map(({ value, label }: any) => {
                    return {
                      value,
                      label,
                    };
                  })}
                  label="Weeks of Month"
                  fullWidth
                  multiple
                  disabled={closed}
                />
              </Grid>
              <Grid item xs={12} sm={2} style={closed ? { opacity: 0.5 } : {}}>
                <Field
                  label="Notes"
                  component={TextField}
                  name={`${availability}.description`}
                  validate={maxLenth40}
                  fullWidth
                  disabled={closed}
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  name={`${availability}.closed`}
                  component={Checkbox}
                  onChange={(e: any, value: any) => {
                    if (value) {
                      change(`mapAvailabilities[${index}]`, { day: index });
                    }
                  }}
                  label="Closed"
                />
              </Grid>
            </Grid>
            <br />
          </Fragment>
        );
      })}
    </>
  );
};

export default Availabilities;
