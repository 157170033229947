import { Button, Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Field, Form, reduxForm } from 'redux-form';

import { mapCategoriesQuery } from '../../apollo';
import Loading from '../../helpers/Loading';
import Checkbox from '../form-fields/Checkbox';
import DropDownMenu from '../form-fields/DropDownMenu';

const EditMap = reduxForm({
  form: 'editMap',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})((props: any) => {
  const { handleSubmit, submitting, submitFunc } = props;

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
  } = useQuery(mapCategoriesQuery);

  if (loadingCategories) return <Loading error={errorCategories} />;

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit((values: any) => submitFunc(values))}
    >
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
        style={{ fontWeight: 700 }}
      >
        MAP CONFIGURATION
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <br />
          <Field
            name="categories"
            component={DropDownMenu}
            options={get(dataCategories, 'mapCategories', []).map(
              ({ id, name }: any) => {
                return {
                  value: id,
                  label: name,
                };
              },
            )}
            label="Categories"
            fullWidth
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="enableAvailability"
            component={Checkbox}
            label="Show Availability Filter"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="enableAttributes"
            component={Checkbox}
            label="Show Attribute Filter"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <br />
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        disabled={submitting}
      >
        Submit
      </Button>
    </Form>
  );
});

export default EditMap;
