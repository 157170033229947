import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, formValueSelector } from 'redux-form';

import { required, hexColor } from '../../helpers/validators';
import ResponsiveDialog from '../ResponsiveDialog';
import ColorPicker from '../form-fields/ColorPicker';
import TextField from '../form-fields/TextField';

const EditCategoryForm = reduxForm({ form: 'editForm' })((props: any) => {
  const {
    dispatch,
    reset,
    handleSubmit,
    submitting,
    editFunc,
    deleteFunc,
    onRequestClose,
    initialValues,
    selectedColor,
  } = props;

  const submitAndReset = (data: any) =>
    editFunc(data).then((resData: any) => {
      if (resData) {
        onRequestClose();
        dispatch(reset('editForm'));
      }
    });

  const deleteAndReset = () => {
    return deleteFunc().then((resData: any) => {
      if (resData) {
        onRequestClose();
        dispatch(reset('editForm'));
      }
    });
  };

  return (
    <ResponsiveDialog open onClose={onRequestClose}>
      <Form autoComplete="off" onSubmit={handleSubmit(submitAndReset)}>
        <DialogTitle id="form-dialog-title">Category Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the required information, submit to save.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="name"
                component={TextField}
                label="Name"
                validate={required}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              <Field
                name="color"
                component={ColorPicker}
                label="Color"
                validate={[required, hexColor]}
                required
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                marginBottom: 200,
              }}
            >
              <RoomIcon fontSize="large" htmlColor={selectedColor} />
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            {initialValues && (
              <Button
                onClick={deleteAndReset}
                color="secondary"
                variant="outlined"
                disabled={submitting}
                style={{ marginRight: 'auto' }}
              >
                Delete
              </Button>
            )}
            <Button
              onClick={onRequestClose}
              color="primary"
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={submitting}
            >
              {submitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  &nbsp; Submit
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </ResponsiveDialog>
  );
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector('editForm');

  return {
    selectedColor: selector(state, 'color'),
  };
};

export default connect(mapStateToProps)(EditCategoryForm);
