import getMapSiteAvailabilities from './getMapSiteAvailabilities';

const formatMapSiteAvailability = (mapSite: any) => {
  if (!mapSite.mapAvailabilities.length) {
    return '';
  }

  const availabilities = getMapSiteAvailabilities(mapSite);

  const formatted = availabilities.map(({ day, hours, weekInfo }: any) => {
    if (hours === 'Closed') {
      return `${day} Closed`;
    }

    return `${day} ${hours}${weekInfo ? ' ' : ''}${weekInfo || ''}`;
  });

  return formatted.join('\n');
};

export default formatMapSiteAvailability;
