import gql from 'graphql-tag';

export default gql`
  {
    mapConfig {
      id
      mapConfigId
      bannerEnabled
      bannerText
    }
  }
`;
