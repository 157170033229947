import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Backdrop,
} from '@material-ui/core';
import get from 'lodash/get';
import React from 'react';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, FieldArray } from 'redux-form';

import {
  mapOrganizationsQuery,
  mapCategoriesQuery,
  mapAttributesQuery,
  mapDemographicsQuery,
} from '../../apollo';
import days from '../../constants/days';
import states from '../../constants/states';
import Loading from '../../helpers/Loading';
import { normalizePhone } from '../../helpers/normalizers';
import {
  required,
  latitude,
  longitude,
  validNumber,
  requirelng,
  requirelat,
  validZipCode,
  validateAvailabilities,
  validPhoneNumber,
  validEmails,
} from '../../helpers/validators';
import ResponsiveDialog from '../ResponsiveDialog';
import Availabilities from '../form-fields/Availabilities';
import Checkbox from '../form-fields/Checkbox';
import ChipTextField from '../form-fields/ChipTextField';
import DropDownMenu from '../form-fields/DropDownMenu';
import DropDownMenuClassic from '../form-fields/DropDownMenuClassic';
import TextField from '../form-fields/TextField';

const EditSiteForm = reduxForm({
  form: 'editSiteForm',
  validate: validateAvailabilities,
})((props: any) => {
  const {
    dispatch,
    reset,
    handleSubmit,
    submitting,
    editSite,
    deleteSite,
    onRequestClose,
    initialValues,
    change,
    deleteLoading,
  } = props;

  const submitAndReset = (data: any) =>
    editSite(data).then((resData: any) => {
      if (resData) {
        onRequestClose();
        dispatch(reset('editSiteForm'));
      }
    });

  const deleteAndReset = () => {
    return deleteSite().then((resData: any) => {
      if (resData) {
        onRequestClose();
        dispatch(reset('editSiteForm'));
      }
    });
  };

  const { loading: loadingOrgs, error: errorOrgs, data: dataOrgs } = useQuery(
    mapOrganizationsQuery,
  );
  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
  } = useQuery(mapCategoriesQuery);
  const {
    loading: loadingAttributes,
    error: errorAttributes,
    data: dataAttributes,
  } = useQuery(mapAttributesQuery);
  const {
    loading: loadingDemographics,
    error: errorDemographics,
    data: dataDemographics,
  } = useQuery(mapDemographicsQuery);

  const queryError =
    errorOrgs || errorCategories || errorAttributes || errorDemographics;

  if (
    loadingOrgs ||
    loadingCategories ||
    loadingAttributes ||
    loadingDemographics
  )
    return (
      <Backdrop open transitionDuration={0} style={{ zIndex: 9999 }}>
        <Loading />
      </Backdrop>
    );

  if (queryError) {
    onRequestClose();
    return null;
  }

  const mapDemographicEthnicities = get(
    dataDemographics,
    'mapDemographics.ethnicities',
    [],
  ).map(({ id, name }: any) => {
    return {
      value: id,
      label: name,
    };
  });

  mapDemographicEthnicities.unshift({
    label: 'Ethnicities',
    value: null,
    group: true,
  });

  return (
    <ResponsiveDialog open onClose={onRequestClose}>
      <Form autoComplete="off" onSubmit={handleSubmit(submitAndReset)}>
        <DialogTitle id="form-dialog-title">Site Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the required information, submit to save.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="showOnMap"
                component={Checkbox}
                label="Show on Map"
                required
                fullWidth
              />
              <Field
                name="mobileAppSync"
                component={Checkbox}
                label="Sync to Mobile App"
                required
                fullWidth
              />
              <Field
                // disable for Milestone 2
                disabled
                name="nutritiousFoodEmail"
                component={Checkbox}
                label="Receive Nutritious Emails"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="mapOrganizationId"
                component={DropDownMenu}
                options={get(dataOrgs, 'mapOrganizations', []).map(
                  ({ id, name }: any) => {
                    return {
                      value: id,
                      label: name,
                    };
                  },
                )}
                label="Organization"
                fullWidth
                includeNone
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="mapCategoryId"
                component={DropDownMenu}
                options={get(dataCategories, 'mapCategories', []).map(
                  ({ id, name }: any) => {
                    return {
                      value: id,
                      label: name,
                    };
                  },
                )}
                label="Category"
                fullWidth
                includeNone
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="mapAttributeIds"
                component={DropDownMenu}
                options={get(dataAttributes, 'mapAttributes', []).map(
                  ({ id, name }: any) => {
                    return {
                      value: id,
                      label: name,
                    };
                  },
                )}
                label="Attributes"
                fullWidth
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="name"
                component={TextField}
                label="Name"
                validate={required}
                required
                fullWidth
                autoComplete="input-unsupported"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address1"
                component={TextField}
                label="Address"
                validate={required}
                required
                fullWidth
                autoComplete="input-unsupported"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address2"
                component={TextField}
                label="Address 2"
                fullWidth
                autoComplete="input-unsupported"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="city"
                component={TextField}
                label="City"
                validate={required}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="state"
                component={DropDownMenuClassic}
                options={states.options}
                label="State"
                validate={required}
                required
                fullWidth
                autoComplete="input-unsupported"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="zip"
                component={TextField}
                label="Zip"
                validate={[required, validZipCode]}
                required
                fullWidth
                autoComplete="input-unsupported"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="longitude"
                component={TextField}
                label="Longitude"
                validate={[validNumber, longitude, requirelng]}
                fullWidth
                helperText="Longitude &amp; Latitude are generated when submitted"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="latitude"
                component={TextField}
                label="Latitude"
                validate={[validNumber, latitude, requirelat]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Contact & Misc information</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="contactName"
                component={TextField}
                label="Contact Name"
                fullWidth
                autoComplete="input-unsupported"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="contactPhone"
                component={TextField}
                label="Contact Phone"
                validate={validPhoneNumber}
                normalize={normalizePhone}
                fullWidth
                autoComplete="input-unsupported"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="liaisonName"
                component={TextField}
                label="Liaison Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="websiteUrl"
                component={TextField}
                label="Website URL"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="ceresId"
                component={TextField}
                label="Netsuit ID"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="link2feedId"
                component={TextField}
                label="Link2Feed ID"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                // disable for Milestone 2
                disabled
                name="contactEmails"
                component={ChipTextField}
                validate={validEmails}
                label="Contact Emails"
                fullWidth
                normalize={(value: any) =>
                  value.map((val: string) => val.trim())
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                // disable for Milestone 2
                disabled
                name="mapDemographicIds"
                component={DropDownMenu}
                options={mapDemographicEthnicities}
                label="Demographics"
                fullWidth
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={TextField}
                label="Description"
                fullWidth
                multiline
                rows={2}
                rowsMax={6}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Hours of Operation</Typography>
            </Grid>
            <Grid item xs={12}>
              <FieldArray
                name="mapAvailabilities"
                component={Availabilities}
                rerenderOnEveryChange
                change={change}
              />
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            {initialValues && (
              <Button
                onClick={deleteAndReset}
                color="secondary"
                variant="outlined"
                disabled={submitting || deleteLoading}
                style={{ marginRight: 'auto' }}
              >
                Delete
              </Button>
            )}
            <Button
              onClick={onRequestClose}
              color="primary"
              disabled={submitting || deleteLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={submitting || deleteLoading}
            >
              {submitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  &nbsp; Submit
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </ResponsiveDialog>
  );
});

const mapStateToProps = (state: any, ownProps: any) => {
  const mapAvailabilities = days.values.map((dayOfWeek: string) => {
    const existingAvailability = ownProps?.initialValues?.mapAvailabilities.find(
      ({ day }: any) => {
        return day === Number(dayOfWeek);
      },
    );

    if (existingAvailability) {
      return existingAvailability;
    }

    return {
      day: Number(dayOfWeek),
      closed: false,
    };
  });

  return {
    initialValues: {
      showOnMap: true,
      mobileAppSync: false,
      ...ownProps.initialValues,
      mapAvailabilities,
    },
  };
};

export default connect(mapStateToProps)(EditSiteForm);
