import gql from 'graphql-tag';

export default gql`
  mutation($input: MapOrganizationInput!, $id: ID) {
    editMapOrganization(input: $input, id: $id) {
      id
      name
      description
      lastUpdated
    }
  }
`;
