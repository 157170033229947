import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { endSession } from '../../helpers/session';
import HFBLogo from '../../images/HFB-logo.png';
import { logoutAction } from '../../redux';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#FFF',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    flexGrow: 1,
  },
  dividerRoot: {
    color: 'rgba(0, 0, 0, 0.12)',
    margin: 15,
  },
}));

const Topbar = (props: any) => {
  const { onSidebarOpen, dispatch, ...rest } = props;
  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root)}>
      <Toolbar>
        <Hidden mdDown>
          <img src={HFBLogo} width={125} alt="" className="hfb" />
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Divider
          orientation="vertical"
          flexItem
          className={classes.dividerRoot}
        />
        <Typography variant="h6" className={classes.logo}>
          Map Admin
        </Typography>
        <Button
          color="inherit"
          onClick={() => {
            endSession();
            dispatch(logoutAction());
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default connect()(Topbar);
