import {
  Avatar,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';

import { geocodeMapSiteMutation } from '../../apollo';
import handleToastError from '../../helpers/handleToastError';
import EditSite from '../EditSite';
import { ModalContext } from '../Modal/ModalContext';

const SitesTable = ({ data }: any) => {
  const modal = useContext(ModalContext);
  const [columnOptions, setColumnOptions] = useState<any>(
    JSON.parse(localStorage?.getItem('visibleMapSiteColumns') as string) || [
      'mapCategory',
      'name',
      'address',
      'geocodedAddress',
      'showOnMap',
      'lastUpdated',
      'mapAttributes',
      'hoursOfOperation',
      'partnerEthnicities',
    ],
  );

  const [rowsPerPage, setRowsPerPage] = useState<string>(
    localStorage?.getItem('sitesRowsPerPage') || '25',
  );

  const [sortOrder, setSortOrder] = useState<any>(
    JSON.parse(localStorage?.getItem('sitesSortOrder') as string) || {
      name: 'lastUpdated',
      direction: 'desc',
    },
  );

  useEffect(() => {
    localStorage?.setItem(
      'visibleMapSiteColumns',
      JSON.stringify(columnOptions),
    );
  }, [columnOptions]);

  useEffect(() => {
    localStorage?.setItem('sitesRowsPerPage', String(rowsPerPage));
  }, [rowsPerPage]);

  useEffect(() => {
    localStorage?.setItem('sitesSortOrder', JSON.stringify(sortOrder));
  }, [sortOrder]);

  const formattedResults = useMemo(
    () =>
      data.map(
        ({
          id,
          mapOrganization,
          mapCategory,
          mapAttributes,
          mapAvailabilities,
          lastUpdated,
          lastEditedBy,
          name,
          address1,
          address2,
          city,
          state,
          zip,
          geocodedAddress,
          showOnMap,
          mobileAppSync,
          nutritiousFoodEmail,
          partnerEthnicities,
        }: any) => {
          let address = `${address1}${address2 ? ' ' : ''}${
            address2 || ''
          }, ${city}, ${state} ${zip}`;

          if (!city || !state || !zip) {
            address = 'Incomplete';
          }

          let hoursOfOperation = 'None';

          if (mapAvailabilities.length) {
            if (mapAvailabilities.length === 7) {
              hoursOfOperation = 'Complete';
            } else {
              hoursOfOperation = 'Incomplete';
            }
          }

          return {
            id,
            mapOrganization: mapOrganization?.name,
            mapCategory: mapCategory?.name,
            mapAttributes: mapAttributes.map(({ name }: any) => name),
            hoursOfOperation,
            name,
            address,
            lastUpdated,
            lastEditedBy,
            geocodedAddress,
            showOnMap,
            mobileAppSync,
            nutritiousFoodEmail,
            partnerEthnicities: partnerEthnicities.map(({ name }: any) => name),
          };
        },
      ),
    [data],
  ) as [];

  const attributeOptions = formattedResults.reduce(
    (prevResult: any, formattedResult: any) => {
      formattedResult.mapAttributes.forEach((attribute: any) => {
        if (prevResult.indexOf(attribute) === -1) {
          prevResult.push(attribute);
        }
      });

      return prevResult;
    },
    ['None'],
  );

  const demographicOptions = formattedResults.reduce(
    (prevResult: any, formattedResult: any) => {
      formattedResult.partnerEthnicities.forEach((ethnicity: any) => {
        if (prevResult.indexOf(ethnicity) === -1) {
          prevResult.push(ethnicity);
        }
      });

      return prevResult;
    },
    ['None'],
  );

  const columns = useMemo(
    () => [
      {
        name: 'id',
        options: {
          display: 'excluded',
          filter: false,
          sort: false,
          searchable: false,
        },
      },
      {
        name: 'mapOrganization',
        label: 'Organization',
        options: {
          display: columnOptions.includes('mapOrganization'),
          filterOptions: {
            fullWidth: true,
          },
          customFilterListOptions: {
            render: (v: any) => `Organization: ${v}`,
          },
          customBodyRender: (value: any) => value || 'None',
        },
      },
      {
        name: 'mapCategory',
        label: 'Category',
        options: {
          display: columnOptions.includes('mapCategory'),
          filterOptions: {
            fullWidth: true,
          },
          customFilterListOptions: {
            render: (v: any) => `Category: ${v}`,
          },
          customBodyRender: (value: any) => value || 'None',
        },
      },
      {
        name: 'mapAttributes',
        label: 'Attributes',
        options: {
          display: columnOptions.includes('mapAttributes'),
          filterOptions: {
            fullWidth: true,
            names: attributeOptions,
            logic: (attributes: any, filters: any) => {
              return !attributes.split(', ').includes(filters?.[0]);
            },
          },
          customFilterListOptions: {
            render: (v: any) => `Attribute: ${v}`,
          },
          customBodyRender: (value: any) => {
            if (value.length) {
              return value.join(', ');
            }
            return 'None';
          },
        },
      },
      {
        name: 'partnerEthnicities',
        label: 'Demographics',
        options: {
          display: columnOptions.includes('partnerEthnicities'),
          filterOptions: {
            fullWidth: true,
            names: demographicOptions,
            logic: (demographic: any, filters: any) => {
              return !demographic.split(', ').includes(filters?.[0]);
            },
          },
          customFilterListOptions: {
            render: (v: any) => `Demographic: ${v}`,
          },
          customBodyRender: (value: any) => {
            if (value.length) {
              return value.join(', ');
            }
            return 'None';
          },
        },
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          display: columnOptions.includes('name'),
          filter: false,
        },
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          display: columnOptions.includes('address'),
          filter: false,
        },
      },
      {
        name: 'hoursOfOperation',
        label: 'Hours of Operation',
        options: {
          searchable: false,
          display: columnOptions.includes('hoursOfOperation'),
          filterOptions: {
            fullWidth: true,
          },
          customFilterListOptions: {
            render: (v: any) => `Hours of Operation: ${v}`,
          },
          customBodyRender: (value: any, tableMeta: any) => {
            if (value === 'Complete') {
              return (
                <Tooltip title="Complete">
                  <AccessTimeIcon color="primary" />
                </Tooltip>
              );
            }

            if (value === 'Incomplete') {
              return (
                <Tooltip title="Incomplete">
                  <AccessTimeIcon color="primary" style={{ fill: '#FFCC00' }} />
                </Tooltip>
              );
            }

            if (value === 'None') {
              return (
                <Tooltip title="None">
                  <AccessTimeIcon color="error" />
                </Tooltip>
              );
            }
          },
        },
      },
      {
        name: 'geocodedAddress',
        label: 'Geocoded Address',
        options: {
          searchable: false,
          display: columnOptions.includes('geocodedAddress'),
          filterOptions: {
            fullWidth: true,
            renderValue: (v: any) => (v ? 'Yes' : 'No'),
          },
          customFilterListOptions: {
            render: (v: any) =>
              v ? 'Geocoded Address: Yes' : 'Geocoded Address: No',
          },
          customBodyRender: (value: any, tableMeta: any) => {
            if (value) {
              return <CheckCircleIcon color="primary" />;
            }

            return (
              <Tooltip title="Google is unable to geocode address, update or click to retry">
                <Avatar
                  style={{
                    backgroundColor: 'transparent',
                    height: 24,
                    width: 24,
                    overflow: 'initial',
                  }}
                >
                  <Mutation
                    mutation={geocodeMapSiteMutation}
                    onError={(mutationError: any) =>
                      handleToastError(mutationError)
                    }
                    onCompleted={(res: any) => {
                      if (res?.geocodeMapSite?.geocodedAddress) {
                        toast('Site Updated');
                      } else {
                        toast(
                          'Unable to geocode address, update and try again',
                        );
                      }
                    }}
                  >
                    {(mutation: any, { loading }: any) => {
                      return (
                        <IconButton
                          disabled={loading}
                          onClick={(e) => {
                            e.stopPropagation();
                            mutation({
                              variables: {
                                id: tableMeta?.rowData?.[0],
                              },
                            });
                          }}
                        >
                          {loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SyncProblemIcon color="error" />
                          )}
                        </IconButton>
                      );
                    }}
                  </Mutation>
                </Avatar>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'showOnMap',
        label: 'Show on Map',
        options: {
          searchable: false,
          display: columnOptions.includes('showOnMap'),
          customBodyRender: (value: any) => {
            if (value) {
              return <CheckIcon color="primary" />;
            }

            return <ClearIcon color="error" />;
          },
          filterOptions: {
            fullWidth: true,
            renderValue: (v: any) => (v ? 'Yes' : 'No'),
          },
          customFilterListOptions: {
            render: (v: any) => (v ? 'Show on Map: Yes' : 'Show on Map: No'),
          },
        },
      },
      {
        name: 'mobileAppSync',
        label: 'Sync to Mobile App',
        options: {
          searchable: false,
          display: columnOptions.includes('mobileAppSync'),
          customBodyRender: (value: any) => {
            if (value) {
              return <CheckIcon color="primary" />;
            }

            return <ClearIcon color="error" />;
          },
          filterOptions: {
            fullWidth: true,
            renderValue: (v: any) => (v ? 'Yes' : 'No'),
          },
          customFilterListOptions: {
            render: (v: any) =>
              v ? 'Sync to Mobile App: Yes' : 'Sync to Mobile App: No',
          },
        },
      },
      {
        name: 'nutritiousFoodEmail',
        label: 'Receive Nutritious Emails',
        options: {
          searchable: false,
          display: columnOptions.includes('nutritiousFoodEmail'),
          customBodyRender: (value: any) => {
            if (value) {
              return <CheckIcon color="primary" />;
            }

            return <ClearIcon color="error" />;
          },
          filterOptions: {
            fullWidth: true,
            renderValue: (v: any) => (v ? 'Yes' : 'No'),
          },
          customFilterListOptions: {
            render: (v: any) =>
              v
                ? 'Receive Nutritious Emails: Yes'
                : 'Receive Nutritious Emails: No',
          },
        },
      },
      {
        name: 'lastUpdated',
        label: 'Last Updated',
        options: {
          display: columnOptions.includes('lastUpdated'),
          filter: false,
          customBodyRender: (value: any) =>
            moment(value).format('M/D/YY, h:mm A'),
        },
      },
      {
        name: 'lastEditedBy',
        label: 'Last Edited By',
        options: {
          display: columnOptions.includes('lastEditedBy'),
          filter: false,
        },
      },
    ],
    [attributeOptions, demographicOptions, columnOptions],
  );

  const options = useMemo(
    () => ({
      print: false,
      resizableColumns: true,
      download: false,
      elevation: 1,
      rowsPerPage: Number(rowsPerPage),
      rowsPerPageOptions: [25, 50, 100],
      selectableRows: 'none',
      enableNestedDataAccess: '.',
      sortOrder,
      customSearchRender: debounceSearchRender(500),
      onRowClick: (rowData: any) => {
        modal.showModal(EditSite, {
          initialValues: {
            ...data.find(({ id }: any) => id === rowData[0]),
          },
        });
      },
      onChangeRowsPerPage: (numberOfRows: number) =>
        setRowsPerPage(String(numberOfRows)),
      onColumnSortChange: (changedColumn: string, direction: string) => {
        setSortOrder({
          name: changedColumn,
          direction,
        });
      },
      onViewColumnsChange: (changedColumn: string, action: string) => {
        const visibleColumns = cloneDeep(columnOptions);
        if (action === 'add' && !visibleColumns.includes(changedColumn)) {
          visibleColumns.push(changedColumn);
        }
        if (action === 'remove' && visibleColumns.includes(changedColumn)) {
          const indexOfItem = visibleColumns.indexOf(changedColumn);
          visibleColumns.splice(indexOfItem, 1);
        }
        setColumnOptions(visibleColumns);
      },
    }),
    [columnOptions, data, modal, rowsPerPage, sortOrder],
  ) as any;

  return (
    <Fragment>
      <MUIDataTable
        title="Sites"
        data={formattedResults}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};

export default SitesTable;
