import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  ListSubheader,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DropDownMenu = ({
  input,
  label: formLabel,
  helperText,
  required,
  includeNone,
  multiple,
  options,
  meta: { touched, error },
  ...custom
}: any) => {
  const displayedOptions = includeNone
    ? [
        {
          label: 'None',
          value: null,
        }, // Ability to deselect
        ...options,
      ]
    : options;

  return (
    <FormControl
      {...custom}
      variant="outlined"
      error={Boolean(touched && error)}
      size="small"
    >
      <InputLabel required={required} error={Boolean(touched && error)}>
        {formLabel}
      </InputLabel>
      <Select
        multiple={multiple}
        value={input.value || []}
        onChange={input.onChange}
      >
        {displayedOptions.map(({ label, value, group }: any) => {
          if (group) {
            return <ListSubheader key={label}>{label}</ListSubheader>;
          }

          return (
            <MenuItem
              key={value || 'deselect'}
              value={value}
              style={{
                fontWeight:
                  input.value.indexOf(value) !== -1 ? 'bold' : 'initial',
                backgroundColor:
                  input.value.indexOf(value) !== -1
                    ? 'rgba(0, 0, 0, 0.12)'
                    : 'initial',
              }}
            >
              {label || ''}
            </MenuItem>
          );
        })}
      </Select>
      {(helperText || (touched && error)) && (
        <FormHelperText error={Boolean(touched && error)}>
          {touched && error ? error : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

DropDownMenu.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  options: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  includeNone: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  meta: PropTypes.object.isRequired,
};

DropDownMenu.defaultProps = {
  helperText: undefined,
  required: false,
  multiple: false,
  includeNone: false,
};

export default DropDownMenu;
